import '../styles/blogs.scss';

import React, { useEffect, useState } from 'react';

import blog_img from '../images/images/blog-logo.svg';

// Converted To Component To Enable Lazy Loading
const blogData = [
  { type: 'Life Insurance', color: '#0d4d90', background: 'rgba(13, 77, 144, 0.1)' },
  { type: 'Others', color: '#5e5e5e', background: 'rgba(207, 207, 207, 0.3)' },
  { type: 'Home Insurance', color: '#9375ff', background: 'rgba(147, 117, 255, 0.1)' }
];

function HomePageBlogs() {

  const [ wpData, setWpData ] = useState( [] );
  useEffect( () => {
    async function fetchData(){
      try {
        const response = await fetch( 'https://www.getmybubble.com/blog/wp-json/wp/v2/posts?per_page=3' );
        const data = await response.json();
        setWpData( data );
      } catch ( error ) {
        setWpData( [] );
      }
    }

    fetchData();
  }, [] );

  return(
    <>
      {
        wpData.map( ( post, i ) => {
          return ( <div className="card" key={i}>
            <a href={'https://www.getmybubble.com/blog/' + post.slug} target="_blank" rel="noopener noreferrer" className="textcolor" >
              <picture>
                <img src={post.featured_image_urls.large[0] ||  blog_img } alt={post.title.rendered} loading='lazy' className={post.featured_image_urls.large[0] ? 'card-img-top' : 'card-img-top blogimgcss' } />
              </picture>

              <div className="d-flex justify-content-between" style={{ padding: '5px 17px', marginTop: '15px' }}>
                <div className="blog-type" style={{ color: blogData[ i % 3].color, backgroundColor: blogData[ i % 3].background }}>
                  {blogData[ i % 3].type}
                </div>

                <div className="card-read-time">12 Min Read</div>
              </div>

              <div className="card-body" style={{ marginTop: '-15px' }}>
                <h5 className="card-title" dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h5>

                <p className="card-text" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></p>
              </div>
            </a>
          </div> );
        } )
      }
    </>
  );
}

export default HomePageBlogs;
